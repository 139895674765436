import React from 'react'

function Team({ title, children }) {
  return (
    <section className="team">
      <div className="container">
        <h2
          className="team__heading
    underline text--3 "
        >
          {title}
        </h2>{' '}
        <ul className="team__list">{children}</ul>
      </div>{' '}
    </section>
  )
}

export default Team
