import React from 'react'

function OurApproach(props) {
  return (
    <section className="our-approach">
      <div className="container">
        <div className="our-approach__inner">
          <h2
            className="our-approach__heading
                         underline
                         text--3"
          >
            {props.title}
          </h2>
          <p
            className="our-approach__paragraph
                        text--5
                        u-text-center"
          >
            {props.paragraph1}
          </p>
          {props.paragraph2 ? (
            <p
              className="our-approach__paragraph
                            text--5
                            u-text-center"
            >
              {props.paragraph2}
            </p>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default OurApproach
