import React from 'react'

function WeAre(props) {
  return (
    <section className={'we-are ' + (props.isReverse ? 'we-are--reverse' : '')}>
      <div className="container-large">
        <div className="we-are__inner">
          <div className="we-are__content">
            <h2
              className={
                `we-are__heading underline text--3 ` +
                (props.isLong ? `we-are__heading--long` : ``)
              }
            >
              {props.heading}
            </h2>
            <p
              className="we-are__paragraph
                          text--5"
            >
              {props.children}
            </p>
            <p
              className="we-are__paragraph--bg
                          text--3"
            >
              {props.sideText}
            </p>
            <div className="we-are__line underline" />
          </div>

          <div className="we-are__img-wrapper">
            <img src={props.img} alt="Team work" className="we-are__img" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WeAre
