import React from 'react'

import CharityboosterLogo from '../../images/chb-logo.svg';
import Node40Logo from '../../images/node-40-logo.svg';
import AvatierLogo from '../../images/avatier-logo.svg';

// TODO: Improve UX for the slider on mobile (arrows or sth)

function OurClients(props) {
  return (
    <section
      className={'clients ' + (props.borderTop ? 'clients--border-top' : '')}
    >
      <div className="container">
        <div className="clients__inner">
          <h2
            className={
              'clients__heading underline ' +
              (props.small ? 'text--4' : 'text--3')
            }
          >
            {props.heading}
          </h2>
          <div className="clinets__list-wrapper">
            <ul className="clients__list">
              {/* <li className="clients__item">
                <img src="https://cdn.filestackcontent.com/security=policy:eyJleHBpcnkiOjE3MDI0ODA5MDIsImNhbGwiOlsicmVhZCIsImNvbnZlcnQiXSwiaGFuZGxlIjoiQ3BISjZwZG1RRHVEU1VDMzdPQkEifQ==,signature:932ca4b4650feb782c51230a5f328c078fd253fdaf5cc7c9be1dd96886883494/CpHJ6pdmQDuDSUC37OBA"
                      alt="Radium logo"
                      className="clients__img"/>
              </li> */}
              {/*<li className="clients__item">*/}
              {/*  <img*/}
              {/*    src="https://cdn.emersoft.co/emersoft-website/logos/burneikasports-logo.jpeg"*/}
              {/*    alt="BurneikaSports logo"*/}
              {/*    className="clients__img"*/}
              {/*  />*/}
              {/*</li>*/}
              <li className="clients__item">
                <a href='https://node40.com' target="_blank" className="clients__item-link">
                  <img
                    src={Node40Logo}
                    alt="Node40 logo"
                    className="clients__img"
                  />
                </a>
              </li>
              <li
                className="clients__item
                            clients__item--big"
              >
                <a href="https://lightspeedvt.com/" target="_blank" className="clients__item-link">
                  <img
                    src="https://cdn.emersoft.co/emersoft-website/logos/Lightspeed_Logo.jpg"
                    alt="LightspeedVT logo"
                    className="clients__img"
                  />
                </a>
              </li>
              <li className="clients__item">
                <a href="https://charitybooster.com/" target="_blank" className="clients__item-link">
                  <img
                    src={CharityboosterLogo}
                    alt="Charitybooster logo"
                    className="clients__img"
                  />
                </a>
              </li>
              {/* <li className="clients__item">
                <img src="https://cdn.filestackcontent.com/security=policy:eyJleHBpcnkiOjE3MDI0ODA5MDIsImNhbGwiOlsicmVhZCIsImNvbnZlcnQiXSwiaGFuZGxlIjoiTjRZMXJJZVFtTzF3WVhVb1lQZUIifQ==,signature:5c3be637d5ea52f9e48c7509398f5851bb3ad078aa378360ea26d4b629b3686b/N4Y1rIeQmO1wYXUoYPeB"
                      alt="BellaSmiles logo"
                      className="clients__img
                                clients__img--opacity"/>
              </li> */}
              {/* <li className="clients__item">
                <img src="https://cdn.filestackcontent.com/security=policy:eyJleHBpcnkiOjE3MDI0ODA5MDIsImNhbGwiOlsicmVhZCIsImNvbnZlcnQiXSwiaGFuZGxlIjoidFFGSldCaUxTM3FPR2JycHFsbFYifQ==,signature:534dd747b20c1142834a7436e11aa5d3e15d8aaf23880ddfbe70b8076fd4d2ca/tQFJWBiLS3qOGbrpqllV"
                      alt="Optivita logo"
                      className="clients__img
                                clients__img--opacity"/>
              </li> */}

              {/*<li className="clients__item">*/}
              {/*  <img*/}
              {/*    src="https://cdn.emersoft.co/emersoft-website/logos/unitruck-logo.jpeg"*/}
              {/*    alt="Unitruck logo"*/}
              {/*    className="clients__img"*/}
              {/*  />*/}
              {/*</li>*/}
              {/*<li className="clients__item">*/}
              {/*  <img*/}
              {/*    src="https://cdn.emersoft.co/emersoft-website/logos/logo-vooba.png"*/}
              {/*    alt="Vooba logo"*/}
              {/*    className="clients__img"*/}
              {/*  />*/}
              {/*</li>*/}
              <li className="clients__item">
                <a href="https://www.avatier.com/" target="_blank" className="clients__item-link">
                  <img
                    src={AvatierLogo}
                    alt="Avatier Logo"
                    className="clients__img"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurClients
