import React from 'react'

function Member(props) {
  return (
    <li className="team__item">
      <a
        href={props.link}
        className="team__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="team__img-wrapper">
          <img src={props.img} alt="Team Member" className="team__img" />
        </div>
        <div className="team__item-content">
          <h3 className="team__item-heading text--5">{props.name}</h3>
          <p className="team__item-paragraph ">{props.authorRole}</p>
        </div>
      </a>
    </li>
  )
}

export default Member
